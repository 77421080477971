const zh_CN = {
  platform: '佳沛电商数据分析平台',
  Log_In: '登录',
  Log_up: '注册',
  Forget_Password: '忘记密码',
  // ForgotPassword: '重置',
  ReturnLogin: '返回登录',
  Sign_up: '注册',
  Home: '首页',
  CreateInterview: '访谈',
  ExecuteInterview: '研究',
  TestInterview: '测试',
  GeneralKnowledge: '常识',
  Report: 'Report',
  UserCenter: '用户中心',
  Payment: '支付',
  Search: '查找',
  Welcome: '欢迎,',
  Confirm: '确认',
  Manage: '管理',
  UserPoints: '用户',
}
export default zh_CN
